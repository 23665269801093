import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VForm } from 'vuetify/lib/components/VForm';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VTextField } from 'vuetify/lib/components/VTextField';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VContainer,[_c(VRow,[_c(VCol,[_c('div',{staticClass:"page--new-password d-flex justify-center align-center"},[_c('div',{staticClass:"form-container"},[_c(VCard,{staticClass:"pa-10",attrs:{"color":"rgba(255, 255, 255, .8)"}},[_c(VForm,{ref:"form",model:{value:(_vm.valid),callback:function ($$v) {_vm.valid=$$v},expression:"valid"}},[_c(VRow,[_c(VCol,{staticClass:"text-center"},[_c('img',{attrs:{"src":"/img/logo.svg","alt":"brasão-home-64px"}}),_c('br'),_c('h1',{staticClass:"subtitle-1 blue--text text--darken-4"},[_vm._v(" Nova Senha ")])])],1),_c(VRow,[_c(VCol,[_c(VTextField,{attrs:{"solo":"","hide-details":"auto","placeholder":"Nova senha","type":"password","rules":_vm.passwordRules,"disabled":!_vm.isAccessValid,"maxlength":8},model:{value:(_vm.user.password),callback:function ($$v) {_vm.$set(_vm.user, "password", $$v)},expression:"user.password"}})],1)],1),_c(VRow,[_c(VCol,[_c(VBtn,{attrs:{"block":"","color":"success","loading":_vm.loading,"disabled":!_vm.isAccessValid},on:{"click":function($event){return _vm.changePassword()}}},[_vm._v(" Mudar Senha ")])],1)],1),_c(VRow,[_c(VCol,{staticClass:"py-0 mt-3 text-center"},[_c('small',[_c('router-link',{attrs:{"to":{ path: '/EsqueciASenha' }}},[_vm._v(" Esqueci a senha ")])],1)])],1)],1)],1)],1)])])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }